:root {
  --clr-neutral-100: #fff;
  --clr-neutral-400: #202020;

  --bg-color: var(--clr-neutral-400);
  --txt-color: var(--clr-neutral-100);

  --ff-100: "PP Neue Montreal";
  --ff-200: "PP Neue Montreal book";
  --ff-300: "PP Neue Montreal medium";
  --ff-400: "PP Neue Montreal bold";

  --fs-100: 1.125rem;
  --fs-200: 1.25rem;
  --fs-300: 1.5625rem;
  --fs-400: 2.5rem;
  --fs-500: 3.125rem;
  --fs-900: 4rem;
  --fs-1000: 5rem;
}

@font-face {
  font-family: "PP Neue Montreal medium";
  src: url("Assets/openfont/PPNeueMontreal-Medium.otf");
}

@font-face {
  font-family: "PP Neue Montreal book";
  src: url("Assets/openfont/PPNeueMontreal-Book.otf");
}

@font-face {
  font-family: "PP Neue Montreal bold";
  src: url("Assets/openfont/PPNeueMontreal-Bold.otf");
}

@font-face {
  font-family: "PP Neue Montreal";
  src: url("Assets/openfont/PPNeueMontreal-Thin.otf");
}

@media (max-width: 50em) {
  :root {
    --fs-100: 1rem;
    --fs-200: 1.125rem;
    --fs-300: 1.375rem;
    --fs-400: 1.875rem;
    --fs-500: 2.5rem;
    --fs-900: 4rem;
    --fs-1000: 4.375rem;
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
  text-decoration: none;
  color: var(--clr-neutral-100);
  list-style-type: none;
}

a:hover {
  border-bottom: 2px solid #8b8686;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}
/* Set core body defaults */
body {
  font-family: "PP Neue Montreal";
  letter-spacing: 1px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  color: var(--txt-color);
  font-size: var(--fs-200);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
  border-radius: 17px;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* utility class */

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

h1 {
  font-family: var(--ff-300);
  font-size: var(--fs-900);
  line-height: 70px;
}

h2 {
  font-family: var(--ff-300);
  font-size: var(--fs-500);
}

h3 {
  font-family: var(--ff-300);
  font-size: var(--fs-300);
  margin-bottom: 1rem;
}

main {
  background-color: var(--bg-color);
  /* max-width: 1425px; */
  margin: auto;
}

.container {
  max-width: 1295px;
  margin: auto;
  /* padding: 0 1rem; */
}

.infoSection {
  max-width: 50rem;
  padding-top: 4rem;
  padding-bottom: 10rem;
}

.info-article {
  padding: 2rem 0 10rem 0;
}

.info-article p {
  font-size: var(--fs-200);
}

.twoColumns {
  display: flex;
  gap: 40%;
  margin: 2.7rem 0;
  line-height: 2rem;
}

.process {
  gap: 41%;
}

@media (max-width: 50em) {
  .icon-hamburger {
    transition: 0.3s;
    width: 3rem;
    height: 3rem;
  }

  .icon-hamburger-rotate {
    transform: rotate(-90deg);
    transition: 0.3s;
    width: 3rem;
    height: 3rem;
  }

  .primary-navigation {
    position: absolute;
    top: 6rem;
    background-color: #000;
    width: 100%;
    padding: 1rem 0;
    left: 0;
    text-align: center;
  }

  .primary-navigation a {
    color: var(--clr-neutral-100);
    font-weight: var(--fw-500);
  }

  .pNav-invisible {
    display: none;
  }

  .pNav-visible {
    display: block;
  }

  .infoSection h1 {
    padding-top: 9.5rem;
    font-size: 4rem;
    line-height: 70px;
  }

  .info-article p {
    font-size: var(--fs-200);
  }

  .twoColumns {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 2.7rem 0;
  }

  /* .container {
    max-width: 38em;
    margin: auto;
    padding: 0 1rem;
  } */

  .twoColumns p {
    font-family: var(--ff-200);
  }

  figure .picture-tag {
    font-size: var(--fs-100);
    line-height: 0.8rem;
    position: absolute;
    bottom: 0.5rem;
    left: 1rem;
  }
}

figure {
  margin: 4rem 0;
  position: relative;
}

figure img {
  max-height: 39.0881rem;
}

.picture-tag {
  font-size: var(--fs-300);
  line-height: 1rem;
  position: absolute;
  bottom: 3.5rem;
  left: 2.5rem;
}

.picture-tag h3 {
  font-family: var(--ff-400);
}
