:root {
  --clr-neutral-100: #fff;
  --clr-neutral-400: #202020;

  --bg-color: var(--clr-neutral-400);
  --txt-color: var(--clr-neutral-100);

  --ff-100: "PP Neue Montreal";
  --ff-200: "PP Neue Montreal book";
  --ff-300: "PP Neue Montreal medium";
  --ff-400: "PP Neue Montreal bold";

  --fs-100: 1.125rem;
  --fs-200: 1.25rem;
  --fs-300: 1.5625rem;
  --fs-400: 2.5rem;
  --fs-500: 3.125rem;
  --fs-900: 5.625rem;
  --fs-1500: 9.375rem;
}

@font-face {
  font-family: "PP Neue Montreal medium";
  src: url("Assets/openfont/PPNeueMontreal-Medium.otf");
}

@font-face {
  font-family: "PP Neue Montreal book";
  src: url("Assets/openfont/PPNeueMontreal-Book.otf");
}

@font-face {
  font-family: "PP Neue Montreal bold";
  src: url("Assets/openfont/PPNeueMontreal-Bold.otf");
}

@font-face {
  font-family: "PP Neue Montreal";
  src: url("Assets/openfont/PPNeueMontreal-Thin.otf");
}

@media (max-width: 50em) {
  :root {
    --fs-100: 1rem;
    --fs-200: 1.125rem;
    --fs-300: 1.375rem;
    --fs-400: 1.875rem;
    --fs-500: 2.5rem;
    --fs-900: 4.375rem;
    --fs-1500: 5.375rem;
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
  text-decoration: none;
  color: var(--clr-neutral-100);
  list-style-type: none;
}

a:hover {
  border-bottom: 2px solid #8b8686;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}
/* Set core body defaults */
body {
  font-family: "PP Neue Montreal";
  letter-spacing: 1px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  color: var(--txt-color);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
  border-radius: 17px;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

body {
  background-color: var(--bg-color);
}

main {
  /* max-width: 1425px; */
  margin: auto;
}

nav {
  font-family: var(--ff-300);
  display: flex;
  justify-content: space-between;
  padding: 4.0625rem 0;
  font-size: var(--fs-200);
  position: relative;
}

nav p:first-of-type a:hover {
  border: none;
}

.mobile-nav-toggle {
  display: none;
  position: relative;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.mobile-nav-toggle img {
  border-radius: 0;
}

.about {
  max-width: 22em;
  font-size: var(--fs-200);
  padding: 4rem 0;
}

.about p {
  line-height: 1.5rem;
}

.two-columns {
  display: flex;
  gap: 1.2rem;
  margin: 2.7rem 0;
}

@media (max-width: 50em) {
  .container2 {
    max-width: 38em;
    margin: auto;
    padding: 0 1rem;
  }

  header {
    background-color: var(--bg-color);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  nav {
    padding: 1.5rem 1rem;
    align-items: center;
  }

  nav p:first-of-type a {
    color: var(--clr-neutral-100);
    border: none;
    letter-spacing: 0.05rem;
  }

  .mobile-nav-toggle {
    display: block;
    padding: 0rem 0.3rem;
    background-color: var(--bg-color);
  }

  .icon-hamburger {
    transition: 0.3s;
    width: 3rem;
    height: 3rem;
  }

  .icon-hamburger-rotate {
    transform: rotate(-90deg);
    transition: 0.3s;
    width: 3rem;
    height: 3rem;
  }

  .icon-close {
    display: none;
  }

  .primary-navigation {
    position: absolute;
    top: 6rem;
    background-color: #000;
    width: 100%;
    padding: 1rem 0;
    left: 0;
    text-align: center;
  }

  .primary-navigation a {
    color: var(--clr-neutral-100);
    font-weight: var(--fw-500);
  }

  .pNav-invisible {
    display: none;
  }

  .pNav-visible {
    display: block;
  }

  .about {
    margin-top: 5.8rem;
    padding: 5rem 0;
  }

  .two-columns {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 1.5rem 0;
  }

  .footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .info .contact {
    align-items: flex-start;
  }

  .footer .info {
    align-items: flex-start;
  }

  .container .connect {
    font-size: var(--fs-500);
    padding-top: 2.1875rem;
    padding-bottom: 3.6875rem;
  }
}

.laze,
.karotii,
.renee,
.blox,
.geng,
.eni,
.being,
.amani {
  position: relative;
}

.hidden-tag {
  font-size: var(--fs-100);
  position: absolute;
  bottom: 3rem;
  left: 2.7rem;
  display: none;
}

.card-container:hover .hidden-tag {
  display: block;
}

.hidden-tag h1 {
  font-family: var(--ff-300);
  font-size: 35px;
}

.card-container {
  background-color: hsl(0, 0%, 0%);
  border-radius: 17px;
}

.card-container:hover .card-img {
  /* mix-blend-mode: overlay; */
  opacity: 0.15;
}

.card-name {
  font-family: var(--ff-300);
  margin-top: 1.5rem;
  font-size: var(--fs-300);
}

@keyframes scroll {
  100% {
    transform: translate3d(-200%, 0, 0);
  }
}

.slider {
  display: flex;
  gap: 2rem;
  overflow: hidden;
  position: relative;
}

.slider .slide-track {
  display: flex;
  gap: 2rem;
  animation: scroll 150s linear infinite;
}

.carousel-section {
  border-top: 4px solid #818080;
  border-bottom: 4px solid #818080;
}

.carousel {
  font-family: var(--ff-300);
  font-size: var(--fs-1500);
  color: #ffffff;
  opacity: 0.3;
  border: none;
}

.carousel:hover {
  opacity: 100%;
  transition: 0.3s;
}

.connect {
  font-family: var(--ff-300);
  font-size: var(--fs-500);
  text-align: center;
  padding-top: 9.1875rem;
  padding-bottom: 12.6875rem;
}

.footer {
  font-family: var(--ff-200);
  line-height: 1.7rem;
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
  /* max-width: 1240px; */
  font-size: var(--fs-200);
  max-width: 1295px;
  margin: auto;
  padding-bottom: 7.8125rem;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.25rem;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
