:root {
  --clr-neutral-100: #fff;
  --clr-neutral-400: #202020;

  --bg-color: var(--clr-neutral-400);
  --txt-color: var(--clr-neutral-100);

  --ff-100: "PP Neue Montreal";
  --ff-200: "PP Neue Montreal book";
  --ff-300: "PP Neue Montreal medium";
  --ff-400: "PP Neue Montreal bold";

  --fs-100: 1.125rem;
  --fs-200: 1.25rem;
  --fs-300: 1.5625rem;
  --fs-400: 2.5rem;
  --fs-500: 3.125rem;
  --fs-900: 5.625rem;
  --fs-1500: 9.375rem;
}

@font-face {
  font-family: "PP Neue Montreal medium";
  src: url("Assets/openfont/PPNeueMontreal-Medium.otf");
}

@font-face {
  font-family: "PP Neue Montreal book";
  src: url("Assets/openfont/PPNeueMontreal-Book.otf");
}

@font-face {
  font-family: "PP Neue Montreal bold";
  src: url("Assets/openfont/PPNeueMontreal-Bold.otf");
}

@font-face {
  font-family: "PP Neue Montreal";
  src: url("Assets/openfont/PPNeueMontreal-Thin.otf");
}

@media (max-width: 50em) {
  :root {
    --fs-100: 1rem;
    --fs-200: 1.125rem;
    --fs-300: 1.375rem;
    --fs-400: 1.875rem;
    --fs-500: 2.5rem;
    --fs-900: 4.375rem;
    --fs-1500: 5.375rem;
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
  text-decoration: none;
  color: var(--clr-neutral-100);
  list-style-type: none;
}

a:hover {
  border-bottom: 2px solid #8b8686;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}
/* Set core body defaults */
body {
  font-family: "PP Neue Montreal";
  letter-spacing: 1px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  color: var(--txt-color);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
  border-radius: 17px;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

body {
  background-color: var(--bg-color);
}

main {
  /* max-width: 1425px; */
  margin: auto;
}

.two-columns {
  display: flex;
  gap: 1.2rem;
  margin: 2.7rem 0;
}

@media (max-width: 50em) {
  .container {
    max-width: 39em;
    margin: auto;
    padding: 0 1rem;
  }

  .two-columns {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 1.5rem 0;
  }

  .footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .info .contact {
    align-items: flex-start;
  }

  .footer .info {
    align-items: flex-start;
  }

  .container .connect {
    font-size: var(--fs-500);
    padding-top: 2.1875rem;
    padding-bottom: 3.6875rem;
  }
}

.connect {
  font-family: var(--ff-300);
  font-size: var(--fs-500);
  text-align: center;
  padding-top: 9.1875rem;
  padding-bottom: 12.6875rem;
}

.footer {
  font-family: var(--ff-200);
  line-height: 1.7rem;
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
  /* max-width: 1240px; */
  font-size: var(--fs-200);
  max-width: 1295px;
  margin: auto;
  padding: 0 1rem;
  padding-bottom: 7.8125rem;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.25rem;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
